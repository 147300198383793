const EVENTS = {
  AD_BID_REQUEST: 'adBidRequest',
  AD_BID_RESPONSE: 'adBidResponse',
  AD_BLOCK: 'adBlock',
  AD_BREAK_END: 'adBreakEnd',
  AD_BREAK_START: 'adBreakStart',
  AD_CLICK: 'adClick',
  AD_COMPANIONS: 'adCompanions',
  AD_COMPLETE: 'adComplete',
  AD_ERROR: 'adError',
  AD_IMPRESSION: 'adImpression',
  ADS_MANAGER: 'adsManager',
  AD_META: 'adMeta',
  AD_PAUSE: 'adPause',
  AD_PLAY: 'adPlay',
  AD_REQUEST: 'adRequest',
  AD_SCHEDULE: 'adSchedule',
  AD_SKIPPED: 'adSkipped',
  AD_STARTED: 'adStarted',
  AD_TAG_CREATED: 'adTagCreated',
  AD_TIME: 'adTime',
  AUDIO_TRACK_CHANGED: 'audioTrackChanged',
  AUDIO_TRACKS: 'audioTracks',
  AUTOSTART_NOT_ALLOWED: 'autostartNotAllowed',
  BEFORE_COMPLETE: 'beforeComplete',
  BEFORE_PLAY: 'beforePlay',
  BUFFER: 'buffer',
  BUFFER_CHANGE: 'bufferChange',
  COMPLETE: 'complete',
  ERROR: 'error',
  FIRST_FRAME: 'firstFrame',
  IDLE: 'idle',
  META: 'meta',
  MUTE: 'mute',
  PAUSE: 'pause',
  PLAY: 'play',
  PLAY_ATTEMPT: 'playAttempt',
  PLAY_ATTEMPT_FAILED: 'playAttemptFailed',
  PLAYBACK_RATE_CHANGE: 'playbackRateChanged',
  PLAYLIST: 'playlist',
  PLAYLIST_COMPLETE: 'playlistComplete',
  PLAYLIST_ITEM: 'playlistItem',
  READY: 'ready',
  SEEK: 'seek',
  SEEKED: 'seeked',
  SETUP_ERROR: 'setupError',
  TIME: 'time',
  VOLUME: 'volume',
  WARNING: 'warning',
};

export default EVENTS;
